export default {
  main : 'Home',
  whatIsIPO : 'What is an IPO',
  aboutUs : 'About us',
  advantages : 'Benefits',
  transactionsHistory : 'History of transactions',
  news : 'News',
  personalOffice : 'Personal office',
  tradeAndInvestmentPlatform : 'Trade and investment platform',
  multifunctionalPaymentSystem : 'Multifunctional payment system',
  digitalUnitAccount : 'Digital unit of account',
  medicalCannabisProduction : 'Medical cannabis production',
  trustAssetManagement : 'Trust asset management',
  ipoDescriptionInfo : 'the first company in the post-Soviet space to provide a number of privileged conditions for investing in the IPO stock market for both small and large investors',
  simplifiedInvestment : 'Simplified investment',
  offersInvestorsTransparentTermsAsset : 'offers investors transparent terms of asset management with a reduced entry threshold and assumes all obligations of registration and maintenance of funds',
  completeReporting : 'Complete reporting',
  entireHistoryTransactions : 'The entire history of transactions in the IPO of our team, since the start of work in 2012, is openly available for review.',
  analystTeam : 'Analyst team',
  teamProfessionalAnalysts : 'A team of professional analysts with over 12 years of experience in investing in the stock market is engaged in the search for investment proposals, analysis and risk assessment.',
  documents : 'Documents',
  termsOfUse : 'Terms of Use',
  privacyPolicy : 'Privacy Policy',
  amlPolicy : 'AML policy',
  riskNotification : 'Risk Notification',
  limitationOfLiability : 'Limitation of liability',
  contacts : 'Contacts',
  onTheStockMarketIpo : 'on the stock market IPO',
  investmentsGlobalFastGrowingCompanies : 'Investments in global IPOs of fast-growing companies and promising startups with the first income through',
  days100 : '100 days',
  getIn : 'Get in',
  average : 'Average',
  allocation : 'allocation',
  allocationDescriptionInfo : 'Allocation - the coefficient of satisfaction of an order for the purchase of shares within the framework of an IPO.',
  onApplicationParticipation : 'on application for participation',
  averageReturnFirstDay : 'Average return on the first day of the placement',
  whatIs : 'What is',
  thisInitialPublicOffering : 'this is an initial public offering on the exchange',
  ipoTextInfo1 : 'For a company, this is an opportunity to raise money for business. For you, it is an opportunity to be the first to invest in a promising startup in order to get the highest possible income with a minimum investment.',
  ipoTextInfo2 : 'As a result of the IPO, the status of the business changes, instead of being private, it becomes public and anyone can become a shareholder.',
  searchCompaniesInvestment : 'Search for companies for investment',
  detailedAnalysisAllOffers : 'Detailed analysis of all offers on the market and selection of the most promising ones.',
  brokerAccountCreation : 'Broker account creation',
  registrationAndFamiliarization : 'Registration and familiarization with the filing of applications for participation in the IPO.',
  publicOfferingsShares : 'Public offerings of shares',
  afterAddingShareExchange : 'After adding a share to the exchange, due to increased demand, its price may increase by tens of percent.',
  profitFixation : 'Profit fixation',
  afterEnding : 'After ending',
  timeAfterIpo : 'the time after the IPO, during which it is impossible to sell the shares of this company that were bought.',
  period90days : 'period (~ 93 days), it is possible to close a deal to sell the company\'s shares.',
  market : 'Market',
  in2020 : 'in 2020',
  billions : 'billions',
  attractedInvestments : 'Attracted investments',
  companiesListedOnAmericanStock  : 'Companies listed on American stock exchanges',
  returnTheFirstDayPlacement  : 'Return on the first day of the placement',
  averageStockReturnsToday  : 'Average Stock Returns Today',
  round  : 'Round',
  becomeInvestorFirstRound  : 'Become an investor in the first round!',
  open  : 'Open',
  statistic  : 'statistics',
  successful  : 'Successful',
  deals  : 'deals',
  averageProfitabilityPerTrade  : 'Average profitability per trade',
  brokerAccountsCompany  : 'Broker accounts of the company',
  ourTeam  : 'our team',
  analysts  : 'analysts',
  teamName1  : 'Stanislav Metashev',
  roleTeam1  : 'Executive Director',
  experience1  : 'Higher economic education, experience in the formation and development of sales, teaching experience in the field of economics, more than 10 years of experience in the banking sector.',
  teamName2  : 'Oksana Vanina',
  roleTeam2  : 'Head of Sales and Partnership Development Department',
  experience2  : 'In sales since 2008. Segment b2b. Head of Finance Sales Department since 2017.',
  teamName3  : 'Andrey Chernykh',
  roleTeam3  : 'Asset manager',
  experience3  : 'Has been practicing in the field of investment since 1991. He has a certificate from the Federal Financial Markets Service 1.0 and an MBA in Marketing and Sales Management from the Institute of Business and Business Administration of the Russian Presidential Academy of National Economy and Public Administration.',
  teamName4  : 'Sergey Smith',
  roleTeam4  : 'Investment Architect',
  experience4  : 'Investment architect. In financial markets for over 13 years. He worked as a financial consultant at Goldman Sachs. <br> Organization of production risk hedging departments for a top-10 company in Russia in the oil and gold production sector. <br> Experience with "fixed income" instruments. He was an advisor on a number of large blockchain projects. He is a frequent guest on the RBC and Evronovosti TV channel.',
  whyWithUs  : 'Why with us',
  profitableInvest  : 'profitable to invest',
  personalMoneyManagement  : 'Personal money ',
  personalMoneyManagementEnding  : 'management',
  minimumInvestment  : 'Minimum investment',
  lowRelocationRate  : 'Low relocation rate',
  highCommissionRates  : 'High commission rates',
  dailyMarketAnalytics  : 'Daily market analytics',
  analysisNewAndPromising  : 'Analysis of new and promising IPOs',
  registrationAndCreationApplicationsParticipation  : 'Registration and creation of applications for participation',
  annualIncomeAverage  : 'Annual income is on average 50%',
  investments  : 'Investments',
  with  : 'with',
  theAllocationRateHigherDue  : 'The allocation rate is higher due to the investment volume',
  lowerCommissionDue  : 'Lower commission due to the size of the deposit',
  riskManagementDepartment  : 'Risk Management Department',
  expertAnalysisPotential   : 'Expert analysis of the potential of companies',
  paymentIncomeIn100days  : 'Payment of income in 100 days to a personal account',
  annualReturnAverage  : 'Annual return on average is',
  period  : 'period',
  videoInStartPage  : '/media/images/sections/hero/video-preview-en.webp',
  imageForVideoInStartPage  :  'https://img.youtube.com/vi/c-EicpKiyDw/hqdefault.jpg',
  videoLinkInStartPage  :  'https://www.youtube.com/embed/WxcRqC7Nte8?autoplay=1',
  notAvailable: 'Foundation services are not available for your region',
}