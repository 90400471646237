import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { ROUTES } from './config/constants';
import { getClearedPathname, hasPathnameExtraSlashes } from './utils';

const Main = lazy(() => import('./pages/Main/Main'));


const App = () => {
  const location = useLocation();

  return (
    <Suspense fallback=''>
      {
        hasPathnameExtraSlashes(location.pathname)
          ? <Redirect to={getClearedPathname(location.pathname)} />
          : <Switch>
            <Route exact path={ROUTES.main} component={Main} />
            <Redirect to={ROUTES.main} />
          </Switch>
      }
    </Suspense>
  );
}

export default App;