export const MODAL_TYPES = {
  round1: 'ROUND1',
}

export const LANGUAGES = [
  { value: 'ru', name: 'Русский' },
  { value: 'en', name: 'English' },
  // { value: 'es', name: 'Española' },
]

export const ROUTES = {
  notFound: '/not-found',
  main: '/',
  news: '/news',
}
  
export const LINK_ROUTES = {
  system: 'https://s-forex.io/',
  wallet: 'https://s-wallet.ai/',
  token: 'https://sn-token.io/',
  pharmaceutical: 'https://s-pharmaceutical.health/',
  ipo: window.location.origin,
  cabinet: 'https://backoffice.s-group.io/personal-office',

  main: '',
  signIn: '',
  signUp: 'https://backoffice.s-group.io/sign_up',

  social: {
    en: {
      telegram: 'https://t.me/SGroup_en',
      instagram: 'https://www.instagram.com/SGroup_en',
      facebook: 'https://www.facebook.com/SGroup.en',
      youtube: 'https://www.youtube.com/channel/UCjMumEGncMkciO9txuG1Cjg',
    },

    ru: {
      telegram: 'https://t.me/SGroup_ru',
      instagram: 'https://www.instagram.com/SGroup_ru',
      facebook: 'https://www.facebook.com/SGroup.io.ru',
      youtube: 'https://www.youtube.com/channel/UCjMumEGncMkciO9txuG1Cjg',
    }
  }
}

export const BLOCKED_COUNTRIES = ['US', 'CN', 'SG', 'KP', 'CU', 'IR', 'SD', 'SY'];
