export default {
  main : 'Главная',
  whatIsIPO : 'Что такое  IPO',
  aboutUs : 'О нас',
  advantages : 'Преимущества',
  transactionsHistory : 'История сделок',
  news : 'Новости',
  personalOffice : 'Личный кабинет',
  tradeAndInvestmentPlatform : 'Торгово-инвестиционная платформа',
  multifunctionalPaymentSystem : 'Многофункциональная платежная система',
  digitalUnitAccount : 'Цифровая расчетная единица',
  medicalCannabisProduction : 'Производство медицинского каннабиса',
  trustAssetManagement : 'Доверительное управление активами',
  ipoDescriptionInfo : 'первая на постсоветском пространстве компания предоставляющая ряд привилегированных условий для инвестиций в фондовый рынок IPO как для мелких, так и для крупных инвесторов',
  simplifiedInvestment : 'Упрощенные инвестиции',
  offersInvestorsTransparentTermsAsset : 'предлагает инвесторам прозрачные условия управления активами со сниженым порогом входа и берет на себя все обязательства оформления и обслуживания средств',
  completeReporting : 'Полная отчетность',
  entireHistoryTransactions : 'В открытом доступе для ознакомления абсолютно вся история сделок в IPO нашей команды, с момента начала работы в 2012 году.',
  analystTeam : 'Команда аналитиков',
  teamProfessionalAnalysts : 'Поиском инвестиционных предложений, анализом и оценкой рисков занимается команда проффесиональных аналитиков со стажем более 12 лет в инвестициях на фондовом рынке.',
  documents : 'Документы',
  termsOfUse : 'Условия использования',
  privacyPolicy : 'Политика конфиденциальности',
  amlPolicy : 'Политика AML',
  riskNotification : 'Уведомление о рисках',
  limitationOfLiability : 'Ограничение ответственности',
  contacts : 'Контакты',
  onTheStockMarketIpo : 'на фондовом рынке IPO',
  investmentsGlobalFastGrowingCompanies : 'Инвестиции в мировые ІРО быстрорастущих компаний и перспективных стартапов с первым доходом через',
  days100 : '100 дней',
  getIn : 'Присоединиться',
  average : 'В среднем',
  allocation : 'аллокация',
  allocationDescriptionInfo : 'Аллокация – коэффициент удовлетворения ордера на приобретение акций в рамках IPO.',
  onApplicationParticipation : 'по заявке на участие',
  averageReturnFirstDay : 'Средний доход в первый день размещения акций',
  whatIs : 'Что такое',
  thisInitialPublicOffering : 'это первичное публичное размещение акций на бирже',
  ipoTextInfo1 : 'Для компании это возможность привлечь деньги для бизнеса. Для вас — возможность первым инвестировать в перспективный стартап, чтобы получить как можно больший доход с минимальной инвестицией.',
  ipoTextInfo2 : 'В результате IPO cтатус бизнеса меняется, вместо частного он становится публичным и акционером может стать каждый желающий.',
  searchCompaniesInvestment : 'Поиск компаний для инвестиции',
  detailedAnalysisAllOffers : 'Детальный  разбор всех предложений на рынке и отбор наиболее перспективных.',
  brokerAccountCreation : 'Создание счета у брокера',
  registrationAndFamiliarization : 'Регистрация и ознакомление с подачей заявок на участие в IPO.',
  publicOfferingsShares : 'Публичное размещение акций',
  afterAddingShareExchange : 'После добавления акции на биржу, из-за повышенного спроса её цена  может вырасти на десятки процентов.',
  profitFixation : 'Фиксация прибыли',
  afterEnding : 'После окончания',
  timeAfterIpo : 'время после IPO, в течение которого нельзя продать акции этой компании, которые купили.',
  period90days : 'периода (~93 дней), доступно закрытие сделки по продаже акций компании.',
  market : 'Рынок',
  in2020 : 'в 2020 году',
  billions : 'млрд',
  attractedInvestments : 'Привлечено инвестиций',
  companiesListedOnAmericanStock  : 'Компаний вышедших на американских биржах',
  returnTheFirstDayPlacement  : 'Доходность в первый день размещения акций',
  averageStockReturnsToday  : 'Средняя доходность акций  на сегодня',
  round  : 'Раунд',
  becomeInvestorFirstRound  : 'Становитесь инвестором уже в первом раунде!',
  open  : 'Открытая',
  statistic  : 'статистика',
  successful  : 'Успешных',
  deals  : 'сделок',
  averageProfitabilityPerTrade  : 'Средняя доходность за сделку',
  brokerAccountsCompany  : 'Брокерские счета компании',
  ourTeam  : 'Наша команда',
  analysts  : 'аналитиков',
  teamName1  : 'Станислав Меташев',
  roleTeam1  : 'Исполнительный директор',
  experience1  : 'Высшее экономическое образование, опыт формирования и развития продаж, преподавательский опыт в области экономики, более 10 лет опыта в банковской сфере.',
  teamName2  : 'Оксана Ванина',
  roleTeam2  : 'Руководитель отдела продаж и партнёрского развития',
  experience2  : 'В продажах с 2008 года. Сегмент b2b. Руководитель отдела продаж финансового направления с 2017 года.',
  teamName3  : 'Андрей Черных',
  roleTeam3  : 'Управляющий активами',
  experience3  : 'Практикует в сфере инвестиций с 1991 года. Имеет аттестат ФСФР 1.0 и степень MBA по специализации «Маркетинг и управление продажами» Института бизнеса и делового администрирования Российской академии народного хозяйства и государственной службы при президенте.',
  teamName4  : 'Сергей Смит',
  roleTeam4  : 'Инвестиционный архитектор',
  experience4  : 'Инвестиционный архитектор. На финансовых рынках более 13 лет. Работал финансовым консультантам в Goldman Sachs. <br> Организация отделов по хеджированию производственных рисков для компании топ-10 в России в секторе добычи подработке нефти и золото. <br> Опыт работы с инструментами "фиксированной доходности". Был советником в ряде крупных проектов по блокчейн. Является частым гостем на телеканале РБК и Евроновости.',
  whyWithUs  : 'Почему с нами',
  profitableInvest  : 'выгодно инвестировать',
  personalMoneyManagement  : 'Личное управление ',
  personalMoneyManagementEnding  : 'капиталом',
  minimumInvestment  : 'Минимальная инвестиция',
  lowRelocationRate  : 'Низкий процент алокации',
  highCommissionRates  : 'Высокие ставки комиссий',
  dailyMarketAnalytics  : 'Ежедневная аналитика рынка',
  analysisNewAndPromising  : 'Разбор новых и перспективных IPO',
  registrationAndCreationApplicationsParticipation  : 'Регистрация и создание заявок на участие',
  annualIncomeAverage  : 'Годовой доход в среднем составляет 50%',
  investments  : 'Инвестиции',
  with  : 'c',
  theAllocationRateHigherDue  : 'Процент алокации выше за счет объема инвестиции',
  lowerCommissionDue  : 'Ниже комиссии за счет размера вклада',
  riskManagementDepartment  : 'Отдел риск-менеджмента',
  expertAnalysisPotential   : 'Экспертный анализ потенциала компаний',
  paymentIncomeIn100days  : 'Выплата дохода через 100 дней на личный аккаунт',
  annualReturnAverage  : 'Годовая доходность в среднем составляет',
  period  : 'период',
  videoInStartPage  : '/media/images/sections/hero/video-preview-ru.webp',
  imageForVideoInStartPage  :  'https://img.youtube.com/vi/qhXP2Tdifgs/hqdefault.jpg',
  videoLinkInStartPage  :  'https://www.youtube.com/embed/yP6DqN4mIao?autoplay=1',
  notAvailable: 'Foundation services are not available for your region',
}